<template>
  <v-main>
    <v-row>
      <v-card
        width="700px"
        height="300px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10"
      >
        <v-card-title class="pb-0">
          <h3 style="color: white">Start Dispatch</h3>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-form>
            <v-text-field
              label="CRM Tracking ID"
              prepend-icon="mdi-cellphone"
              v-model.trim="trackingId"
              dark
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="mr-10" :disabled="false" dark @click="caseLookup">
            <v-icon color="#E21E81">mdi-account-search</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="invalidTrackingId" max-width="600px">
        <v-card dark>
          <v-card-title>
            <v-row align="center" justify="center">
              <v-icon color="amber" x-large>mdi-alert</v-icon>
            </v-row>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="center" style="color: #e21e81">
                The dispatch tracking ID entered is invalid.
                Please double check the CCO ID of the service request in Jira.
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="wrongStateTrackingId" max-width="600px" >
        <v-card dark>
          <v-card-title>
            <v-row align="center" justify="center">
              <v-icon color="amber" x-large>mdi-alert</v-icon>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="center" >
                The CRM tracking ID is not in the correct state to start processing dispatch.
                The Jira issue with the CRM tracking ID should meet
                 these conditions in order to start processing:
              </v-row>
              <v-spacer></v-spacer>
              <v-row v-if="wrongStatusTrackingId" align="center" >
                <v-icon color="#E21E81">mdi-alert-octagon</v-icon>
                The Jira issue status MUST be in "Ready(የተዘጋጀ)" state.
              </v-row>
              <v-row v-if="wrongAssignee" align="center">
                <v-icon color="#E21E81">mdi-alert-octagon</v-icon>
                The Jira issue MUST be assigned to you.
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dispatchExists" max-width="600px">
        <v-card dark>
          <v-card-title>
            <v-row align="center" justify="center">
              <v-icon color="amber" x-large>mdi-alert</v-icon>
            </v-row>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text>
            <v-container>
              <v-row align="center" justify="center" style="color: #e21e81">
                Our records show that dispatch has been already completed for {{ this.trackingId }}
                If you want to create another dispatch for the same customer, please create a new
                CRM case through the call center intake process.
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="crmCaseLookedUp">
      <v-card
        width="1400px"
        height="360px"
        class="mt-2 mx-auto mb-4"
        color="grey darken-3"
        elevation="10"
      >
        <v-row>
          <v-col class="ml-8">
            <v-row>
              <v-data-table
              hide-default-footer
              dark
              dense
              class="mb-2"
              :items="lastRequestData"
              :headers="customerInfoHeaders"
              item-key="lastRequestLocation"
            ></v-data-table>
            </v-row>
            <v-row>
              <v-card dark width="600px">
                <v-card-title>
                    <h6>Employer Request Details</h6></v-card-title>
                <v-card-text>
                    {{ serviceRequestDetails }}
                </v-card-text>
              </v-card>
            </v-row>
            <v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <!-- <v-col dense>
                  <v-card color="lime" width="200px">
                    <v-card-text class="my-1 py-2">
                      Customer Location
                    </v-card-text>
                  </v-card>
                </v-col> -->
                <v-col dense>
                  <v-card dark width="280px">
                    <v-card-text class="my-1 py-2">
                      <VueGooglePlaces
                        id="map"
                        :options="{ fields: ['geometry'] }"
                        types="establishment"
                        country="et"
                        @placechanged="getAddressData"
                        style="width: 100%"
                      >
                        <v-text-field
                          dense
                          dark
                          v-model="nearbyLocation"
                          hide-details=""
                          class="ma-0 pt-1"
                          label="Service request location"
                          hint=""
                        />
                      </VueGooglePlaces>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-0" no-gutters>
                <!-- <v-col dense>
                  <v-card color="lime" width="200px">
                    <v-card-text class="my-1 py-2">
                      Search Radius (kms)
                    </v-card-text>
                  </v-card>
                </v-col> -->
                <v-col>
                  <v-slider
                    class="pt-10"
                    width="280px"
                    v-model="queryRadius"
                    label="Query Distance (km)"
                    thumb-color="#e21e81"
                    thumb-label="always"
                    :min="queryMinRadius"
                    :max="queryMaxRadius"
                    dark
                  ></v-slider>
                </v-col>
              </v-row>
            </v-row>
            <v-row>
                 <v-col>
              <v-btn @click="searchProviders">Search</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <v-btn @click="resetQuery">Cancel</v-btn>
            </v-col>
            </v-row>
          </v-col>
          <v-col>
             <v-card
          width="600px"
          height="340px"
          class=""
          color="grey darken-3"
          elevation="10"
        >
          <GmapMap
            :center="center"
            :zoom="13"
            map-type-id="roadmap"
            style="width: 100%; height: 340px"
          >
            <GmapMarker
              :key="center.lat"
              :position="center"
              :clickable="true"
              :draggable="false"
              :icon="{ url: require('../assets/provider_icon.png') }"
              @click="center = center"
            />
            <GmapCircle
              :center="center"
              :radius="queryRadiusMeters"
              :visible="true"
              :options="{
                fillColor: '#e81e21',
                fillOpacity: 0.2,
                strokeColor: '#e81e21',
                strokeWidth: 1.0,
              }"
            ></GmapCircle>
            <GmapMarker
              v-for="(m, index) in queryResultTableItems"
              :key="index"
              :position="m.pos"
              :clickable="true"
              :draggable="false"
              :icon="{ url: require('../assets/user_icon.png') }"
              @click="center = m.pos"
            />
          </GmapMap>
        </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row v-if="serviceProviderSearchRan">
        <v-card
          width="1200px"
          height="1200px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
          <v-card-title class="pb-0">
            <h3 style="color: white">Search Result</h3>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-text v-if="noProvidersFoundNearby">
             <h4 style="color: white">No Providers Found! Search wider ...</h4>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              v-if="queryResultTableItems != []"
              height="280px"
              v-model="selectedProviders"
              :headers="queryResultTableHeaders"
              :items="queryResultTableItems"
              :single-select="queryResultSingleSelect"
              :items-per-page="resultItemsPerPage"
              show-select
              show-expand
              item-key="phoneNumber"
              class="elevation-2"
              sortBy="premium"
              update: sort-desc
              dark
            >
              <template v-slot:item.invitedToday="{ item }">
                <v-icon
                  :color="providerSMSInvitedToday(item.invitedToday).color"
                  small
                >
                  {{ providerSMSInvitedToday(item.invitedToday).icon }}
                </v-icon>
              </template>
              <template v-slot:item.jobs="{ item }">
                <v-card-text v-if="item.jobs == 0" >
                  {{ item.jobs }}
                </v-card-text>
                  <v-btn dark v-else
                :href= "item.jql" target="_blank">
                  {{ item.jobs }} <v-icon> mdi-open-in-new </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.pendingJobs="{ item }">
                <v-card-text v-if="item.pendingJobs == 0" >
                  {{ item.pendingJobs }}
                </v-card-text>
                  <v-btn dark v-else
                :href= "item.jqlPending" target="_blank">
                  {{ item.pendingJobs }} <v-icon> mdi-open-in-new </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.prefered="{ item }">
                <v-icon
                  :color="providerPreferedIcon(item.prefered).color"
                  small
                >
                  {{ providerPreferedIcon(item.prefered).icon }}
                </v-icon>
              </template>
              <template v-slot:item.premium="{ item }">
                <v-icon
                  :color="providerPremiumIcon(item.premium).color"
                  small
                >
                  {{ providerPremiumIcon(item.premium).icon }}
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  {{ item.ad }}
                </td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text dark>
            <v-row align="center" justify="center">
              <h3 style="color:white">Enter connection payment details</h3>
            </v-row>
          </v-card-text>
          <v-card-text>
            <!-- <v-row no-gutters align="center">
              <v-col>
                <v-select
                  v-model="selectedPremiumPackageName"
                  :items="paymentPackages"
                  label="Premium Package"
                  hint="Select Premium Package"
                  persistent-hint
                  ></v-select>
              </v-col>
              <v-col v-if="selectedPremiumPackageName !== null">
                <v-chip class="mx-5"  > ETB {{ selectedPackagePaymentAmount  }}</v-chip>
              </v-col>
            </v-row> -->
            <v-row no-gutters align="center">
              <v-col>
                <v-select
                  v-model="selectedPaymentMethod"
                  :items="paymentMethods"
                  label="Payment Method"
                  hint="Select Payment Method"
                  persistent-hint
                  dark
                  ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  class="mx-5"
                  v-model="connectionServicePaidAmount"
                  label="Enter paid amount (ETB)"
                  outlined
                  dense
                  :rules="numberRules"
                  dark/>
              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <image-cropping
                dark
                formLabel="Payment Receipt"
                v-on:listingPicture="receiptImageData = $event"
              ></image-cropping>
              <span v-if="$v.receiptImageData.$invalid"
                style="color: red; font-size:12px" mb-4>
                <v-icon small color="amber">mdi-alert</v-icon>
                Payment receipt or confirmation screenshot is required!
              </span>
            </v-row>
            <v-row>
              <v-checkbox
                dark
                v-model="connectionPaymentConfirm"
                label="I have verified this payment is correct and would like to submit."
              >
              </v-checkbox>
            </v-row>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="justify-center">
            <v-btn
              :disabled="!readyForDelivery"
              @click="fullfillRequest"
              class="mr-8"
            >
              <v-icon medium color="#E21E81">mdi-send</v-icon>
            </v-btn>
            <v-btn
              @click="resetQuery"
            >Close Request</v-btn>
          </v-card-actions>
        </v-card>
        <v-card
          v-if="bulkSMSHint"
          width="400px"
          height="400px"
          class="mt-2 mx-auto"
          color="grey darken-3"
          elevation="10"
        >
        <v-card-title class="pb-0">
            <h3 style="color: white">Send Bulk SMS</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
            >
              <v-textarea
                v-model="bulkSMSMessage"
                color="#E21E81"
                label="Bulk SMS message to Service Providers"
                :counter="120"
                filled
                required
                multi-line
                dark
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="color:white">
               {{ bulkSMSPhoneList.length }}  SPs selected
              </v-card-text>
        <v-card-actions class="justify-center">
          <v-progress-linear
            v-if="bulkSMSInProgress"
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
          <v-container v-else>
            <!-- <v-row>
              <v-checkbox
                dark
                v-model="postInTelegram"
                label="Post in Telegram Channel"
              ></v-checkbox>
            </v-row> -->
            <v-row>
              <v-btn
                  :disabled="!readyForBulkSMS"
                  @click="sendBulkSMS"
                  class="mr-8">
                <v-icon medium color="#E21E81">mdi-send</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="smsSendingInProgress" max-width="600px">
       <v-card
        width="900px"
        height="100px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Automated SMS message sending in progress ...</h2>
          </v-card-text>
          <v-progress-linear
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    <v-dialog v-model="providerSearchInProgress" max-width="600px">
       <v-card
        width="900px"
        height="100px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Search in progress ...</h2>
          </v-card-text>
          <v-progress-linear
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    <v-dialog v-model="caseLookUpInProgress" max-width="600px">
       <v-card
        width="900px"
        height="100px"
        class="mt-2 mx-auto"
        color="grey darken-3"
        elevation="10">
          <v-card-text>
            <h2 style="color: white" class="my-4 mx-auto">
              Dispatch search in progress ...</h2>
          </v-card-text>
          <v-progress-linear
            indeterminate
            height="20"
            striped
            color="#e21e81"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
  </v-main>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import firebase, { firestore } from 'firebase/app';
import * as geofire from 'geofire-common';
import axios from 'axios';
// import { createReadStream } from 'fs';
import ImageCropping from './ImageCropping.vue';
import 'firebase/storage';

export default {
  components: {
    'image-cropping': ImageCropping,
  },
  validations: {
    receiptImageData: {
      required,
    },
  },
  data() {
    return {
      trackingId: 'CCO-',
      trackingData: null,
      invalidTrackingId: false,
      wrongStatusTrackingId: false,
      wrongAssignee: false,
      wrongStateTrackingId: false,
      dispatchExists: false,
      lastRequestData: [],
      customerInfoHeaders: [
        {
          text: 'Employer  Name',
          value: 'employerName',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Employer Phone Number',
          value: 'employerPhoneNumber',
        },
        {
          text: 'Requested Service',
          value: 'requestedService',
        },
        {
          text: 'Request Date',
          value: 'requestDate',
        },
      ],
      center: { lat: 9.005401, lng: 38.763611 },
      paymentMethods: [
        'MACT Re-Dispatch',
        'CCO Re-Dispatch',
        'BLSR Re-Dispatch',
        'Cash',
        'Awash Bank Transfer',
        'CBE Bank Transfer',
        'Bank of Abyssinia Transfer',
        'Telebirr Wallet',
        'CBE Birr Wallet',
        'Awash Birr Wallet',
        'Debit/Credit Card',
        'Wegagen HelloCash Wallet',
        'Amole Wallet',
      ],
      selectedPaymentMethod: null,
      connectionServicePaidAmount: null,
      numberRules: [
        (v) => !!v || 'Payment is required',
        // eslint-disable-next-line no-restricted-globals
        (v) => (!isNaN(parseFloat(v)) && v >= 0) || 'Should be number',
      ],
      receiptImageData: null,
      connectionPaymentConfirm: false,
      queryLocation: null,
      nearbyLocation: null,
      queryMinRadius: 1,
      queryMaxRadius: 10,
      queryRadius: 1,
      queryService: null,
      queryResultTableHeaders: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'LastName', value: 'lastName' },
        { text: 'Premium', value: 'premium', default: false },
        { text: 'Prefered', value: 'prefered', default: false },
        // { text: 'Verified', value: 'verified' },
        { text: 'Successful Jobs', value: 'jobs' },
        { text: 'Pending Jobs', value: 'pendingJobs' },
        { text: 'SMS Today?', value: 'invitedToday' },
        { text: 'Total Invitations', value: 'totalInvitations' },
        { text: 'Phone', value: 'phoneNumber' },
        { text: 'Services', value: 'services' },
        { text: 'Distance(km)', value: 'distance' },
      ],
      selectedProviders: [],
      queryResultTableItems: [],
      resultItemsPerPage: 10,
      serviceRequestDetails: null,
      noProvidersFoundNearby: false,
      queryResultSingleSelect: false,
      employerFirstName: null,
      employerPhoneNumber: null,
      bulkSMSMessage: '',
      crmCaseLookedUp: false,
      serviceProviderSearchRan: false,
      smsSendingInProgress: false,
      providerSearchInProgress: false,
      caseLookUpInProgress: false,
      bulkSMSInProgress: false,
      postInTelegram: false,
      queryResult: null,
    };
  },
  methods: {
    sendBulkSMS() {
      this.bulkSMSInProgress = true;
      // Bulk SMS payload
      const bulkSMSPayload = {
        bulknotifylist: this.bulkSMSPhoneList,
        service: this.queryService,
        mode: 'bulkspnotification',
        crmtrackingid: this.trackingId,
        message: this.bulkSMSMessage,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      axios
        .post(process.env.VUE_APP_NOTIFY_URL, bulkSMSPayload, config)
        .then(() => {
          // eslint-disable-next-line no-alert
          alert('Bulk SMS is sent!');
          const crmCommentPayLoad = {
            mode: 'comment',
            trackingid: this.trackingId,
            bulksmsmessage: `*Bulk SMS sent out*\n\n*Number of SPs: ${this.selectedProviders.length}*\n\n"${this.bulkSMSMessage}"`,
          };
          console.log(crmCommentPayLoad);
          axios.post(process.env.VUE_APP_GCRM_URL, crmCommentPayLoad, config)
            .then(() => {
              this.bulkSMSMessage = '';
              this.bulkSMSInProgress = false;
              // if (this.postInTelegram) {
              //   const url = `https://api.telegram.org/bot${process.env.VUE_APP_TELEGRAM_BOT_TOKEN}/sendPhone`;
              //   const telegramPayLoad = {
              //     chat_id: process.env.VUE_APP_TELEGRAM_CHATID,
              //     photo: createReadStream(),
              //     caption: this.bulkSMSMessage,
              //   };
              //   axios.post(url, telegramPayLoad);
              // }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    caseLookup() {
      // start clean
      this.lastRequestData = [];
      this.crmCaseLookedUp = false;
      this.caseLookUpInProgress = true;
      this.resetCaseLookup();
      const db = firebase.firestore();
      //   curl -X POST http://localhost:8089/gcrm -H 'Content-Type: application/json' -H 'Accept: application/json' -d '{"trackingid": "CCO-7", "mode": "lookup" }' | jq .
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      const crmPayLoad = {
        trackingid: this.trackingId,
        mode: 'lookup',
      };
      axios
        .post(process.env.VUE_APP_GCRM_URL, crmPayLoad, config)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === '<nil>') {
            this.invalidTrackingId = true;
          } else {
            const jiraStatus = resp.data.fields.status.name;
            const jiraAssignee = resp.data.fields.assignee;
            this.trackingData = resp.data.fields;
            const jiraFields = resp.data.fields;
            if ((jiraStatus === 'Assigned' || jiraStatus === 'Ready') && jiraAssignee != null) {
              if (jiraAssignee.emailAddress === this.gdoAgent) {
                //   proceed
                // verify dispatch is not already processed for this cco issue
                // if there is an existing dispatch in callcenter_dispatch
                // notify to create another crm case
                db.collection('cc_dispatch').where('callId', '==', this.trackingId)
                  .get()
                  .then((dRecSnapshot) => {
                    if (dRecSnapshot.empty) {
                      // present the cco request data
                      console.log('good to go');
                      this.lastRequestData = [{
                        employerName: jiraFields.customfield_10035,
                        employerPhoneNumber: jiraFields.customfield_10036,
                        requestedService: jiraFields.customfield_10034,
                        requestDate: jiraFields.customfield_10045,
                      }];
                      this.queryService = jiraFields.customfield_10034;
                      this.serviceRequestDetails = jiraFields.customfield_10037;
                      db.collection('cc_requests').where('callId', '==', this.trackingId)
                        .get()
                        .then((cRecSnapshot) => {
                          if (!cRecSnapshot.empty) {
                            const intakeData = cRecSnapshot.docs[0].data();
                            console.log(intakeData);
                            this.center = {
                              lat: intakeData.requestLocation.latitude,
                              lng: intakeData.requestLocation.longitude,
                            };
                            this.nearbyLocation = intakeData.requestLocation.name;
                            this.employerFirstName = intakeData.firstName;
                            this.employerPhoneNumber = intakeData.phoneNumber;
                          }
                          this.crmCaseLookedUp = true;
                        })
                        .catch((error) => console.log(error));

                      // provide an action button to search providers changing radius
                      //  and allow updating location

                      // fullfilment action button to send SMS and
                      //    update callcenter_dispatch collection
                    } else {
                      this.dispatchExists = true;
                    }
                  })
                  .catch((error) => console.log(error));
              } else {
                this.wrongAssignee = true;
                this.wrongStateTrackingId = true;
              }
            } else {
              this.wrongAssignee = true;
              this.wrongStatusTrackingId = true;
              this.wrongStateTrackingId = true;
            }
          }
          this.caseLookUpInProgress = false;
        })
        .catch();
    },
    resetCaseLookup() {
      this.wrongStatusTrackingId = false;
      this.wrongAssignee = false;
      this.wrongStateTrackingId = false;
      this.selectedPaymentMethod = null;
      this.trackingData = null;
      this.connectionServicePaidAmount = null;
      this.receiptImageData = null;
      this.connectionPaymentConfirm = false;
    },
    getAddressData(addressData) {
      // console.log(addressData);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
      this.queryLocation = addressData;
      this.nearbyLocation = addressData.formatted_address;
    },
    providerVerifiedIcon(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-shield-check', color: 'lime' }
        : { icon: 'mdi-close-octagon', color: 'red' };
    },
    providerPreferedIcon(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-star-shooting', color: 'lime' }
        : { icon: 'mdi-close-octagon', color: 'red' };
    },
    providerPremiumIcon(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-cash', color: 'lime' }
        : { icon: 'mdi-close-octagon', color: 'red' };
    },
    providerSMSInvitedToday(b) {
      // console.log(`provider verified : ${b}`);
      return b
        ? { icon: 'mdi-check', color: '#E81E21' }
        : { icon: 'mdi-close-octagon', color: 'grey' };
    },
    searchProviders() {
      this.providerSearchInProgress = true;
      const db = firebase.firestore();
      const queryBounds = geofire.geohashQueryBounds(
        [this.center.lat, this.center.lng],
        this.queryRadiusMeters,
      );
      const promises = [];
      queryBounds.forEach((b) => {
        const q = db
          .collection('goodays')
          .orderBy('geoLoc.geohash')
          .startAt(b[0])
          .endAt(b[1]);
        promises.push(q.get());
      });
      Promise.all(promises)
        .then((snapshots) => {
          const matchingDocs = [];
          // eslint-disable-next-line no-unused-vars
          snapshots.forEach((snap) => {
            snap.forEach((doc) => {
              // console.log(doc.data().geoLoc.geopoint);
              const lat = doc.data().geoLoc.geopoint.latitude;
              const lng = doc.data().geoLoc.geopoint.longitude;
              const distanceInKm = geofire.distanceBetween(
                [lat, lng],
                [this.center.lat, this.center.lng],
              );
              const distanceInM = distanceInKm * 1000;
              if (
                distanceInM <= this.queryRadiusMeters
                && doc.data().services != null
              ) {
                if (doc.data().services.includes(this.queryService) && doc.data().approvedUser) {
                  matchingDocs.push({
                    provider: doc.data(),
                    id: doc.id,
                    distance: distanceInKm,
                  });
                }
              }
            });
          });
          this.queryResult = matchingDocs;
          if (this.queryResult.length === 0) {
            this.noProvidersFoundNearby = true;
          } else {
            this.noProvidersFoundNearby = false;
          }
          // console.log(this.queryResult);
          return matchingDocs;
        })
        .then((matchingDocs) => {
          const providerTabelList = [];
          matchingDocs.forEach((data) => {
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            };
            const crmUpdatePayLoad = {
              mode: 'jqlSuccess',
              gigworker: data.provider.phoneNumber,
            };
            // console.log(crmUpdatePayLoad);
            axios.post(process.env.VUE_APP_GCRM_URL, crmUpdatePayLoad, config)
              .then((SuccessResp) => {
                // console.log(resp.data);
                const crmPendingQueryPayLoad = {
                  mode: 'jqlPending',
                  gigworker: data.provider.phoneNumber,
                };
                axios.post(process.env.VUE_APP_GCRM_URL, crmPendingQueryPayLoad, config)
                  .then((PendingResp) => {
                    db.collection('cc_gig_invitations').doc(data.id).collection('invitations').get()
                      .then((invitationsResp) => {
                        let invitationsTotal = 0;
                        let invitedToday = false;
                        if (!invitationsResp.empty) {
                          // start of the day
                          const startToday = new Date();
                          startToday.setHours(0);
                          startToday.setMinutes(0);
                          startToday.setSeconds(0);
                          // console.log(`today ${startToday}`);
                          // console.log(`invite day ${invitationsResp.docs[0].data().timeStamp}`);
                          invitedToday = invitationsResp.docs
                            .find((i) => i.data().timeStamp.toDate() > startToday) !== undefined;
                          // console.log(`Invited today: ${invitedToday}`);
                          invitationsTotal = invitationsResp.docs.length;
                        }
                        providerTabelList.push({
                          firstName: data.provider.firstName,
                          lastName: data.provider.lastName,
                          phoneNumber: data.provider.phoneNumber,
                          services: data.provider.services,
                          distance: data.distance.toFixed(2),
                          ad: data.provider.summary,
                          pos: {
                            lat: data.provider.geoLoc.geopoint.latitude,
                            lng: data.provider.geoLoc.geopoint.longitude,
                          },
                          verified: data.provider.approvedUser,
                          prefered: data.provider.preferedServiceProvider,
                          premium: data.provider.premiumProvider,
                          jobs: SuccessResp.data.length,
                          pendingJobs: PendingResp.data.length,
                          totalInvitations: invitationsTotal,
                          invitedToday,
                          jql: `https://gooday.atlassian.net/issues/?jql=((project = "Corporate Businesses Board"  AND status = Done) or (project = CCO AND status = Done) or (project = "Bulk Labor Sourcing Request" AND status = Successful) or (project = "Mobile Application Call Tracking" AND status = "Done - Success (የተሳካ ቅጥር)" ) or (project = "Telegram Service Request" AND status = "Done" )) AND "Service Provider Phone Number[Short text]" ~ "${data.provider.phoneNumber}" ORDER BY key ASC, cf[10034] ASC, cf[10039] ASC, created DESC`,
                          jqlPending: `https://gooday.atlassian.net/issues/?jql=(project = "Corporate Businesses Board"  or project = CCO or project = "Bulk Labor Sourcing Request"  or project = "Mobile Application Call Tracking" or project = "Telegram Service Request") AND "Service Provider Phone Number[Short text]" ~ "${data.provider.phoneNumber}" AND statusCategory != Done ORDER BY key ASC, cf[10034] ASC, cf[10039] ASC, created DESC`,
                        });
                      })
                      .catch((error) => console.log(error));
                  })
                  .catch((error) => console.log(error));
              }).catch((error) => console.log(error));
          });

          // matchingDocs.forEach((data) => {
          //   providerTabelList.push({
          //     firstName: data.provider.firstName,
          //     lastName: data.provider.lastName,
          //     phoneNumber: data.provider.phoneNumber,
          //     services: data.provider.services,
          //     distance: data.distance.toFixed(2),
          //     ad: data.provider.summary,
          //     pos: {
          //       lat: data.provider.geoLoc.geopoint.latitude,
          //       lng: data.provider.geoLoc.geopoint.longitude,
          //     },
          //     verified: data.provider.approvedUser,
          //     prefered: data.provider.preferedServiceProvider,
          //     premium: data.provider.premiumProvider,
          //   });
          // });
          this.queryResultTableItems = providerTabelList;
          this.supplyResult = true;
          this.serviceProviderSearchRan = true;
          this.providerSearchInProgress = false;
        })
        .catch((error) => console.log(error));
    },
    fullfillRequest() {
      this.smsSendingInProgress = true;
      const db = firebase.firestore();
      const timeNow = new Date();
      this.selectedProviders.forEach((e) => {
        if (e.prefered !== true) {
          e.prefered = false;
        }
        if (e.premium !== true) {
          e.premium = false;
        }
      });
      const dispatchData = {
        nearbyLocation: this.nearbyLocation,
        callId: this.trackingId,
        requestedService: this.queryService,
        serviceProvidersSupplied: this.selectedProviders,
        timeStamp: firestore.Timestamp.fromDate(timeNow),
        dispatchAgent: this.gdoAgent,
      };
      console.log(dispatchData);
      // console.log(customerRequestData);
      db.collection('cc_dispatch')
        .add(
          dispatchData,
        )
        .then(() => {
          if (this.queryResult.length > 0) {
            // update JIRA
            // curl -X POST http://localhost:8089/gcrm
            // -H 'Content-Type: application/json'
            //  -H 'Accept: application/json'
            // -d '{"mode": "dispatch","trackingid":"CCO-5",
            //  "gigworkername": "Abeba Bekele",
            //  "gigworker": "+251911121315",
            // "providerdispatchtime": "2022-03-26T19:33:05-0400",
            //  "providerdistance": 5, "dispatchagent": "dispatcher@gooday.io" }'
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            };
            const crmUpdatePayLoad = {
              mode: 'dispatch',
              trackingid: this.trackingId,
              gigworkername: `${this.selectedProviders[0].firstName} ${this.selectedProviders[0].lastName}`,
              gigworker: this.selectedProviders[0].phoneNumber,
              providerdispatchtime: this.jiraDateFormat(timeNow),
              providerdistance: parseFloat(this.selectedProviders[0].distance),
              dispatchagent: this.gdoAgent,
            };
            console.log(crmUpdatePayLoad);
            axios.post(process.env.VUE_APP_GCRM_URL, crmUpdatePayLoad, config)
              .then((resp) => {
                console.log(resp.data);
                if (resp == null) {
                  // eslint-disable-next-line no-alert
                  alert('failed to update CRM tracking');
                } else {
                  // send SMS
                  let message = `Gooday ${this.queryService} nearby\n`;
                  this.selectedProviders.forEach((provider) => {
                    message += `${provider.firstName} - ${provider.phoneNumber}\n`;
                  });
                  // Automated service integration goes here ---
                  const smsPayLoad = {
                    employername: this.employerFirstName,
                    gigworkername: this.selectedProviders[0].firstName,
                    employer: this.employerPhoneNumber,
                    gigworker: this.selectedProviders[0].phoneNumber,
                    service: this.queryService,
                  };
                  console.log(message);
                  console.log(smsPayLoad);
                  // process payment
                  this.receiptImageData.toBlob(async (imageBlob) => {
                    const uploadTask = firebase.storage().ref().child(`Payments/ccoDispatch/${this.trackingId}`).put(imageBlob);
                    uploadTask.on(
                      firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and
                        //   the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // eslint-disable-next-line no-console
                        console.log(`Upload is ${progress}% done`);
                        switch (snapshot.state) {
                          case firebase.storage.TaskState.PAUSED: // or 'paused'
                            // eslint-disable-next-line no-console
                            console.log('Upload is paused');
                            break;
                          case firebase.storage.TaskState.RUNNING: // or 'running'
                            // eslint-disable-next-line no-console
                            console.log('Upload is running');
                            break;
                          case firebase.storage.TaskState.ERROR:
                            console.log('Error occured uploading image data');
                            break;
                          case firebase.storage.TaskState.SUCCESS:
                            console.log('Upload is successful');
                            break;
                          default:
                            console.log('something weird happened');
                            break;
                        }
                      },
                      (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                          case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            // eslint-disable-next-line no-console
                            console.log('user doesnt have permission');
                            break;

                          case 'storage/canceled':
                            // User canceled the upload
                            // eslint-disable-next-line no-console
                            console.log('upload is canceled');
                            break;

                          case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            // eslint-disable-next-line no-console
                            console.log('unknown error');
                            break;
                          default:
                            console.log('unexpected error occured');
                            break;
                        }
                      },
                      () => {
                        uploadTask.snapshot.ref.getDownloadURL()
                          .then((receiptURL) => {
                            console.log(`${receiptURL}`);
                            db.collection('payment_transactions')
                              .doc('CCO')
                              .collection('payments')
                              .add({
                                amount: this.connectionServicePaidAmount,
                                paymentDescription: 'cco connection fee',
                                timeStamp: firestore.Timestamp.fromDate(timeNow),
                                currency: 'ETB',
                                firstName: this.trackingData.customfield_10035,
                                lastName: this.trackingData.customfield_10035,
                                paymentSubmittedBy: this.gdoAgent,
                                packageUnits: 1,
                                paymentAmount: this.connectionServicePaidAmount,
                                paymentMethod: this.selectedPaymentMethod,
                                phoneNumber: this.trackingData.customfield_10036,
                                processed: true,
                                invoiceNumber: 'manualDispatch',
                                mode: 'GDO',
                                reference: 'manual',
                                serviceProvider: false,
                                status: 'success',
                                txReference: this.trackingId,
                                type: this.selectedPaymentMethod,
                                userId: 'CCO',
                              })
                              .then(() => {
                                axios.post(process.env.VUE_APP_NOTIFY_URL, smsPayLoad, config)
                                  .then(() => {
                                    // post in telegram
                                    this.resetQuery();
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                                this.smsSendingInProgress = false;
                                // eslint-disable-next-line no-alert
                                alert(message);
                              })
                              .catch((error) => console.log(error));
                          })
                          .catch((error) => console.log(error));
                      },
                    );
                  });
                }
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    jiraDateFormat(dd) {
      // 2022-03-26T19:33:05-0400
      // const dd = date.toDate();
      const m = dd.getMonth() + 1;
      const d = dd.getDate();
      const y = dd.getFullYear();
      const h = dd.getHours();
      const M = dd.getMinutes();
      return `${y}-${m <= 9 ? `0${m}` : m
      }-${d <= 9 ? `0${d}` : d}T${h <= 9 ? `0${h}` : h
      }:${M <= 9 ? `0${M}` : M}`;
    },
    resetQuery() {
      this.trackingId = 'CCO-';
      this.lastRequestData = [];
      this.crmCaseLookedUp = false;
      this.serviceProviderSearchRan = false;
      this.queryService = null;
      this.queryLocation = null;
      this.queryRadius = 1;
      this.queryResult = null;
      this.selectedProviders = [];
      this.queryResultTableItems = [];
      this.selectedPaymentMethod = null;
      this.trackingData = null;
      this.connectionServicePaidAmount = null;
      this.receiptImageData = null;
      this.connectionPaymentConfirm = false;
    },
  },
  computed: {
    gdoAgent() {
      return this.$store.getters['auth/email'];
    },
    queryRadiusMeters() {
      return this.queryRadius * 1000;
    },
    readyForDelivery() {
      return (
        this.selectedProviders.length === 1
        && this.selectedPaymentMethod !== null
        // eslint-disable-next-line no-restricted-globals
        && !isNaN(parseFloat(this.connectionServicePaidAmount))
        && this.connectionServicePaidAmount !== null
        && (this.connectionServicePaidAmount >= 50
        || this.selectedPaymentMethod === 'MACT Re-Dispatch'
        || this.selectedPaymentMethod === 'BLSR Re-Dispatch'
        || this.selectedPaymentMethod === 'CCO Re-Dispatch')
        && this.connectionPaymentConfirm
        && !this.$v.receiptImageData.$invalid
      );
    },
    bulkSMSHint() {
      return this.selectedProviders.length > 1;
    },
    readyForBulkSMS() {
      return (this.bulkSMSMessage.length > 20) && (this.bulkSMSMessage.length < 120);
    },
    bulkSMSPhoneList() {
      const phoneList = [];
      this.selectedProviders.forEach((e) => phoneList.push(e.phoneNumber));
      return phoneList;
    },
  },
};
</script>
